import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

type ProductsSliderSkeletonProps = {
    count: number;
};

export const ProductsSliderSkeleton: FC<ProductsSliderSkeletonProps> = ({ count }) => {
    return (
        <div className="relative -mx-5 mb-4 min-w-full overflow-hidden pl-5 lg:pl-5 lg:pt-5 xl:m-0 xl:px-0 xl:py-[15px]">
            <Skeleton className="mb-2 h-8 !w-3/12" />
            <div className="flex flex-row overflow-hidden">
                {Array(count)
                    .fill(null)
                    .map((_, i) => {
                        return (
                            <div
                                className="mr-4 flex w-full min-w-[290px] flex-col rounded border border-border p-2"
                                key={i}
                            >
                                <Skeleton className="mb-6 h-32 md:mb-14 md:h-52" />
                                <Skeleton className="mb-2 h-8" />
                                <Skeleton count={2} />
                                <Skeleton className="mb-4" />
                                <Skeleton className="h-14" />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

ProductsSliderSkeleton.displayName = 'ProductsSliderSkeleton';
