import { FC } from 'react';
import twMerge from 'utils/twMerge';

type ProductAdvantagesProps = {
    htmlContent: string;
    small?: boolean;
};

const TEST_IDENTIFIER = 'product-advantages';

export const ProductAdvantages: FC<ProductAdvantagesProps> = ({ htmlContent, small = false }) => {
    return (
        <div
            className={twMerge(
                '[&_ul]:mb-0 [&_ul]:flex [&_ul]:flex-wrap',
                '[&_li]:relative [&_li]:mb-5 [&_li]:w-full [&_li]:pl-5 [&_li]:text-small [&_li]:font-semiBold [&_li]:leading-4 [&_li]:!text-base',
                '[&_li_p]:mb-0 [&_li_p]:text-small [&_li_p]:font-semiBold [&_li_p]:leading-4',
                "[&_li]:before:absolute [&_li]:before:left-0 [&_li]:before:top-0 [&_li]:before:text-big [&_li]:before:font-base [&_li]:before:text-green [&_li]:before:content-['+']",
                small && '[&_li]:mb-2 [&_li]:text-extraSmall [&_li]:leading-5',
                small && '[&_li_p]:mb-0 [&_li_p]:text-extraSmall [&_li_p]:leading-5 [&_li_p]:!text-base',
            )}
            data-testid={TEST_IDENTIFIER}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
    );
};

ProductAdvantages.displayName = 'ProductAdvantages';
