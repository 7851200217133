import { isProductToDelivery } from 'helpers/product/toDelivery';
import { useDomainConfig } from 'hooks/useDomainConfig';
import Head from 'next/head';
import { FC } from 'react';
import { ListedProductType } from 'types/product';
import { ListedProductAccessoriesFragmentApi } from 'graphql/generated';

type ProductListMetadataProps = {
    products: ListedProductType[] | ListedProductAccessoriesFragmentApi[];
    section: string;
};

export const ProductListMetadata: FC<ProductListMetadataProps> = ({ products, section = 'productList' }) => {
    const { url, currencyCode } = useDomainConfig();
    const htmlContent: {
        '@context': string;
        '@type': string;
        name: string;
        image: string | undefined;
        sku: string;
        mpn: string | null;
        brand: { '@type': string; name: string | undefined };
        offers: {
            '@type': string;
            url: string;
            priceCurrency: string;
            price: number;
            itemCondition: string;
            availability: string;
        };
    }[] = [];

    for (const product of products) {
        const imageUrl = product.firstImage?.url;
        const slugWithoutLeadingSlash = product.slug.charAt(0) === '/' ? product.slug.slice(1) : product.slug;
        const absoluteUrl = url + slugWithoutLeadingSlash;
        htmlContent.push({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.fullName,
            image: imageUrl,
            sku: product.catalogNumber,
            mpn: product.ean,
            brand: {
                '@type': 'Brand',
                name: product.brand?.name,
            },
            offers: {
                '@type': 'Offer',
                url: absoluteUrl,
                priceCurrency: currencyCode,
                price:
                    typeof product.price.priceWithVat === 'string'
                        ? Number.parseFloat(product.price.priceWithVat)
                        : product.price.priceWithVat,
                itemCondition: 'https://schema.org/NewCondition',
                availability:
                    product.availability.status === 'in-stock'
                        ? 'https://schema.org/InStock'
                        : isProductToDelivery(product)
                        ? 'https://schema.org/InStock'
                        : 'https://schema.org/OutOfStock',
            },
        });
    }

    return (
        <Head>
            <script
                type="application/ld+json"
                id={section + '-metadata'}
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(htmlContent),
                }}
                key={section + '-metadata'}
            />
        </Head>
    );
};
