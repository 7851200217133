import { ListedProductType, ProductColorChooserType } from 'types/product';
import { ListedProductAccessoriesFragmentApi } from 'graphql/generated';

export const resolveStockOnTheWayOptions = (
    product: ListedProductType | ListedProductAccessoriesFragmentApi,
    colorVariants?: ProductColorChooserType[],
) => {
    let showStockOnTheWay: boolean = false;
    let stockOnTheWaySlug: string | undefined;

    if (product.__typename !== 'MainVariant') {
        showStockOnTheWay = product.stockOnTheWay.length > 0;
    } else {
        if (!colorVariants?.some((c) => c.userFilter)) {
            showStockOnTheWay = true;
            stockOnTheWaySlug = product.slug;
        } else {
            const matchingVariants = colorVariants.filter(
                (colorVariant) =>
                    colorVariant.userFilter && colorVariant.variantMatched && colorVariant.stockOnTheWayOrder !== null,
            );

            if (matchingVariants.length > 0) {
                showStockOnTheWay = true;
                const firstVariant = matchingVariants
                    .sort((a, b) => {
                        if (a.stockOnTheWayOrder === null) {
                            return 1;
                        }

                        if (b.stockOnTheWayOrder === null) {
                            return -1;
                        }

                        return a.stockOnTheWayOrder - b.stockOnTheWayOrder;
                    })
                    .shift();

                stockOnTheWaySlug = firstVariant?.link ?? product.slug;
            }
        }
    }

    return { showStockOnTheWay, stockOnTheWaySlug };
};
