import Image from 'components/Basic/Image/Image';
import { Dispatch, FC, ImgHTMLAttributes, SetStateAction, useState } from 'react';
import { ImageType } from 'types/image';
import twMerge from 'utils/twMerge';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { ToggleContent } from 'components/Basic/ToggleContent/ToggleContent';

type ItemProps = {
    link: string;
    name: string;
    variantName: string;
    isActive: boolean;
    smallVariant?: boolean;
    mobileBigVariant?: boolean;
    color: string | null;
    image: ImageType | null;
    variantImage: ImageType | null;
    productImage?: ImageType | null;
    variantMatched: boolean | null;
    userFilter: boolean;
    collectionFilter: boolean;
    setProductImage?: Dispatch<SetStateAction<ImageType | null>>;
    scroll?: boolean;
    loading: ImgHTMLAttributes<HTMLImageElement>['loading'];
    disableHintForFilteredOutVariants?: boolean;
};

export const Item: FC<ItemProps> = ({ scroll = true, ...props }) => {
    const [isHoverActive, setIsHoverActive] = useState(false);
    const t = useTypedTranslationFunction();

    const onMouseEnterHandler = () => {
        setIsHoverActive(true);
        if (props.setProductImage !== undefined && props.variantImage !== null) {
            props.setProductImage(props.variantImage);
        }
    };

    const onMouseLeaveHandler = () => {
        setIsHoverActive(false);
        if (props.setProductImage !== undefined && props.variantImage !== null && props.productImage !== undefined) {
            props.setProductImage(props.productImage);
        }
    };

    const item_twClass = twMerge(
        'ml-[7px] flex h-10 w-10 items-center justify-center rounded-[100%] border-[3px] border-[transparent] bg-[transparent]',
        props.smallVariant && 'ml-0 h-8 w-8',
        props.mobileBigVariant && props.smallVariant && '!h-9 !w-9 sm:!h-8 sm:!w-8',
        (props.isActive || (props.variantMatched !== false && isHoverActive)) && 'border-green',
    );
    const itemIn_twClass = twMerge(
        'relative block h-[34px] w-[34px] overflow-hidden rounded-[100%] border-[2px] border-greyLight2',
        !props.smallVariant && (props.isActive || isHoverActive) && 'border-[4px] border-greyExtremeLight',
        props.smallVariant && 'h-6 w-6',
        props.mobileBigVariant && props.smallVariant && '!h-7 !w-7 sm:!h-6 sm:!w-6',
        props.smallVariant && (props.isActive || isHoverActive) && 'border-[2px] border-greyExtremeLight',
        props.variantMatched === false &&
            '[&>div]:opacity-40 before:z-above before:rotate-45 before:pointer-events-none before:absolute before:top-1/2 before:-translate-y-1/2 before:content-[""] before:w-full before:h-[2px] before:bg-grey border-grey',
    );

    let hoverText = t('This variant does not match the selected filter.');
    if (props.userFilter && props.collectionFilter) {
        hoverText = t('This variant does not match the selected category or filter.');
    } else if (props.collectionFilter) {
        hoverText = t('This variant does not match the selected category.');
    }

    const itemContent = (
        <>
            {props.image !== null && (
                <div className={itemIn_twClass}>
                    <div
                        className={twMerge(
                            'absolute m-[-2px] h-8 w-8 [&_img]:h-8 [&_img]:w-8',
                            !props.smallVariant && (props.isActive || isHoverActive) && '-m-1',
                            props.smallVariant && 'm-[-2px] h-6 w-6 [&_img]:h-6 [&_img]:w-6',
                            props.mobileBigVariant &&
                                props.smallVariant &&
                                '!h-7 !w-7 sm:!h-6 sm:!w-6 [&_img]:h-7 [&_img]:w-7 [&_img]:sm:h-6 [&_img]:sm:w-6',
                            props.smallVariant && (props.isActive || isHoverActive) && 'm-[-2px]',
                        )}
                    >
                        <Image
                            src={props.image.url}
                            alt={props.name}
                            title={props.variantMatched !== false ? props.variantName : undefined}
                            loading={props.loading}
                            width={32}
                            height={32}
                        />
                    </div>
                </div>
            )}

            {props.color !== null && props.image === null && (
                <div className={itemIn_twClass} title={props.variantMatched !== false ? props.variantName : undefined}>
                    <div style={{ backgroundColor: props.color }} className="h-full w-full" />
                </div>
            )}
        </>
    );

    if (props.variantMatched === false) {
        return (
            <ToggleContent
                safePolygonActive={false}
                isDisabled={props.disableHintForFilteredOutVariants}
                component={
                    <div className="text-center">
                        {props.variantName}
                        <br />
                        {hoverText}
                    </div>
                }
            >
                <span
                    className={item_twClass}
                    aria-label={props.variantName}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                >
                    {itemContent}
                </span>
            </ToggleContent>
        );
    }

    return (
        <ExtendedNextLink
            href={props.link}
            scroll={scroll}
            type="product"
            className={item_twClass}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={(event) => event.stopPropagation()}
            aria-label={props.variantName}
        >
            {itemContent}
        </ExtendedNextLink>
    );
};

Item.displayName = 'Item';
