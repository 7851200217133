import { ParameterFragmentApi, ProductInformationTypeOnTileEnumApi } from 'graphql/generated';
import { FC } from 'react';
import { ProductItemRegularParams, ProductItemVariantParams } from './ProductParams';
import { VariantsParametersType } from 'types/product';
import { ProductAdvantages } from 'components/Pages/ProductDetail/ProductAdvantages';

export enum ParamsVariantEnum {
    Regular = 'regular',
    Variant = 'variant',
}

export type ProductInformationOnTileProps = {
    testIdentifier: string;
    paramsVariant: ParamsVariantEnum;
    parameters: ParameterFragmentApi[] | VariantsParametersType;
    informationTypeOnTile: ProductInformationTypeOnTileEnumApi;
    advantages: string | null;
    shortDescription: string | null;
};

export const ProductInformationOnTile: FC<ProductInformationOnTileProps> = (props) => {
    switch (props.informationTypeOnTile) {
        case ProductInformationTypeOnTileEnumApi.ParametersApi:
            return props.paramsVariant === ParamsVariantEnum.Regular ? (
                <ProductItemRegularParams
                    parameters={props.parameters as ParameterFragmentApi[]}
                    testIdentifier={props.testIdentifier}
                ></ProductItemRegularParams>
            ) : (
                <ProductItemVariantParams
                    parameters={props.parameters as VariantsParametersType}
                    testIdentifier={props.testIdentifier}
                ></ProductItemVariantParams>
            );
        case ProductInformationTypeOnTileEnumApi.AdvantagesApi:
            return <>{props.advantages !== null && <ProductAdvantages htmlContent={props.advantages} small />}</>;
        case ProductInformationTypeOnTileEnumApi.ShortDescriptionApi:
            return (
                <>
                    {props.shortDescription !== null && (
                        <div
                            className="mb-2 text-extraSmall leading-5 !text-base [&_p]:mb-0 [&_p]:text-extraSmall [&_p]:leading-5 [&_p]:!text-base"
                            dangerouslySetInnerHTML={{ __html: props.shortDescription }}
                        />
                    )}
                </>
            );
    }
    return <></>;
};

ProductInformationOnTile.displayName = 'ProductInformationOnTile';
