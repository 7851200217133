import { Item } from './Item';
import { Dispatch, FC, ImgHTMLAttributes, SetStateAction } from 'react';
import { ImageType } from 'types/image';
import { ProductColorChooserType } from 'types/product';
import twMerge from 'utils/twMerge';

type ProductColorChooserProps = {
    colors: ProductColorChooserType[];
    activeProductUuid?: string;
    smallVariant?: boolean;
    mobileBigVariant?: boolean;
    productImage?: ImageType | null;
    setProductImage?: Dispatch<SetStateAction<ImageType | null>>;
    scroll?: boolean;
    loading: ImgHTMLAttributes<HTMLImageElement>['loading'];
    disableHintForFilteredOutVariants?: boolean;
};

export const ProductColorChooser: FC<ProductColorChooserProps> = (props) => {
    return (
        <div className={twMerge('-ml-[7px] flex flex-row flex-wrap', props.smallVariant && 'ml-0 justify-center')}>
            {props.colors.map((colorItem, index) => (
                <Item
                    variantName={colorItem.variantName}
                    name={colorItem.name}
                    link={colorItem.link}
                    key={index}
                    isActive={colorItem.variantUuid === props.activeProductUuid}
                    smallVariant={props.smallVariant}
                    mobileBigVariant={props.mobileBigVariant}
                    color={colorItem.color}
                    image={colorItem.image}
                    productImage={props.productImage}
                    variantImage={colorItem.variantImage}
                    setProductImage={props.setProductImage}
                    variantMatched={colorItem.variantMatched}
                    userFilter={colorItem.userFilter}
                    collectionFilter={colorItem.collectionFilter}
                    scroll={props.scroll}
                    loading={props.loading}
                    disableHintForFilteredOutVariants={props.disableHintForFilteredOutVariants}
                />
            ))}
        </div>
    );
};

ProductColorChooser.displayName = 'ProductColorChooser';
