import { FC } from 'react';
import { ParameterFragmentApi } from 'graphql/generated';
import { VariantsParametersType } from 'types/product';

type ProductItemParamsRegularProps = {
    testIdentifier: string;
    parameters: ParameterFragmentApi[];
};

type ProductItemParamsVariantProps = {
    testIdentifier: string;
    parameters: VariantsParametersType;
};

const productItemParamsRow_twClass =
    'flex w-full flex-row flex-nowrap justify-between leading-5 text-base text-extraSmall last:mb-2';

export const ProductItemRegularParams: FC<ProductItemParamsRegularProps> = ({ parameters, testIdentifier }) => {
    return (
        <>
            {parameters.map((paramItem, index) => (
                <div className={productItemParamsRow_twClass} key={index}>
                    <div className="text-left" data-testid={testIdentifier + '-param-name-' + index}>
                        {paramItem.name}:
                    </div>
                    <div className="text-right" data-testid={testIdentifier + '-param-value-' + index}>
                        {paramItem.values
                            .map((item) => item.text)
                            .join(', ')
                            .slice(0, 100)}
                    </div>
                </div>
            ))}
        </>
    );
};

export const ProductItemVariantParams: FC<ProductItemParamsVariantProps> = ({ parameters, testIdentifier }) => {
    return (
        <>
            {Object.values(parameters).map((paramItem, index) => (
                <div className={productItemParamsRow_twClass} key={index}>
                    <div className="text-left" data-testid={testIdentifier + '-param-name-' + index}>
                        {Object.keys(parameters)[index]}:
                    </div>
                    <div className="text-right" data-testid={testIdentifier + '-param-value-' + index}>
                        {paramItem !== null ? paramItem.values[0] : '-'}
                    </div>
                </div>
            ))}
        </>
    );
};

ProductItemRegularParams.displayName = 'ProductItemRegularParams';
ProductItemVariantParams.displayName = 'ProductItemVariantParams';
